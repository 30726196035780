import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { State } from "../../../redux/slices";
import useForkRef from "@mui/utils/useForkRef";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { DateRange, PickersActionBarProps } from "@mui/x-date-pickers-pro";
import { useDispatch, useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  DateRangePicker,
  DateRangePickerProps,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeFieldProps } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import {
  DataSBFilters,
  DataTableRow,
  initialState,
  setDateDataActivity,
  setExtraFiltersValuesAutocomplete,
  setTableDataOptions,
  setVisibleDataRangeDatePicker,
} from "../../../redux/slices/data/data";

import {
  CalendarTodayIconInarix,
  RangePickerButtonData,
} from "../../map/OverviewStyles";

import { localeSelected } from "../../map/mapActivityRangePicker/MapActivityRangePicker";
import { useTranslation } from "react-i18next";
import { Button, DialogActions } from "@mui/material";
import { manageFormat } from "../../dashboard/dashboardPanel/DashboardDateRangePicker";
import { FiltersDataTableObject } from "../dataHelpers/DataProgress";
import { fetchSamples } from "../../../redux/actions/data/dataAsyncActions";
import { setTableOptionsNextPrev } from "../../../redux/slices/data/dataSampleView";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    openRangeDate: () => dispatch(setVisibleDataRangeDatePicker(true)),
    closeRangeDate: () => dispatch(setVisibleDataRangeDatePicker(false)),

    applyFilters: (token: string) => {
      dispatch(setTableDataOptions(initialState.tableOptions));
      dispatch(setTableOptionsNextPrev(initialState.tableOptions));
      dispatch(fetchSamples({ token }));
    },

    setActivityDate: (
      date: Date[],
      dataSBFilters: DataSBFilters,
      map: Record<keyof DataTableRow, keyof DataTableRow>
    ) => {
      dispatch(setDateDataActivity(date));
      dispatch(
        setExtraFiltersValuesAutocomplete({
          ...dataSBFilters,
          filterDate1: FiltersDataTableObject(
            map,
            "pastActionCreationDate",
            date as any,
            "<"
          ),
        })
      );
    },
  };
};

interface DateRangeButtonFieldProps
  extends SingleInputDateRangeFieldProps<Dayjs> {
  openRangeDate?: any;
}

type DateRangeButtonFieldComponent = ((
  props: DateRangeButtonFieldProps & React.RefAttributes<HTMLDivElement>
) => React.JSX.Element) & { fieldType?: string };

const DateRangeButtonField = React.forwardRef(
  (props: DateRangeButtonFieldProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      openRangeDate,
      label,
      id,
      disabled,
      InputProps: { ref: containerRef } = {},
      inputProps: { "aria-label": ariaLabel } = {},
    } = props;

    const handleRef = useForkRef(ref, containerRef);

    return (
      <RangePickerButtonData
        endIcon={<CalendarTodayIconInarix />}
        id={id}
        disabled={disabled}
        ref={handleRef}
        aria-label={ariaLabel}
        onClick={() => openRangeDate()}
      >
        {label}
      </RangePickerButtonData>
    );
  }
) as DateRangeButtonFieldComponent;

DateRangeButtonField.fieldType = "single-input";

const MyActionBar = ({ onAccept, onCancel }: PickersActionBarProps) => {
  const [t] = useTranslation("global");
  return (
    <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
      <Button
        variant="outlined"
        sx={{ textTransform: "none" }}
        onClick={onCancel}
      >
        {t("Common_options.Cancel")}
      </Button>
      <Button
        variant="contained"
        sx={{ textTransform: "none" }}
        onClick={onAccept}
      >
        {t("Common_options.Apply")}
      </Button>
    </DialogActions>
  );
};

const ButtonDateRangePicker = React.forwardRef(
  (
    props: Omit<DateRangePickerProps<Dayjs>, "open" | "onOpen" | "onClose">,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const {
      dataDateRangePickerVisible,
      dataDateActivity,
      dataSBFilters,
      filterMap,
    } = useSelector((state: State) => state.DataReducer);
    const { user } = useSelector((state: State) => state.Authentication);
    const token = user?.token ? user.token : "";
    const { openRangeDate, closeRangeDate, setActivityDate, applyFilters } =
      SetActions();
    const [t] = useTranslation("global");

    return (
      <DateRangePicker
        slots={{
          field: DateRangeButtonField,
          ...props.slots,
          actionBar: MyActionBar,
        }}
        disableFuture={true}
        localeText={{
          cancelButtonLabel: t("Common_options.Apply"),
          okButtonLabel: t("Common_options.Cancel"),
        }}
        value={[dayjs(dataDateActivity[0]), dayjs(dataDateActivity[1])]}
        slotProps={{
          field: { openRangeDate } as any,

          actionBar: {
            actions: ["cancel", "accept"],
          },
        }}
        onChange={(newValue: DateRange<unknown>) => {
          setActivityDate(newValue as Date[], dataSBFilters, filterMap);
        }}
        onAccept={() => {
          applyFilters(token);
        }}
        closeOnSelect={false}
        ref={ref}
        {...props}
        open={dataDateRangePickerVisible}
        onClose={() => closeRangeDate()}
        onOpen={() => openRangeDate()}
      />
    );
  }
);

export default function DataDateRangePickerWithButtonField() {
  const { setActivityDate } = SetActions();
  const { dataDateActivity, dataSBFilters, filterMap } = useSelector(
    (state: State) => state.DataReducer
  );
  const { locale } = useSelector((state: State) => state.LayoutReducer);

  return (
    <LocalizationProvider
      adapterLocale={localeSelected(locale) as unknown as string}
      dateAdapter={AdapterDayjs}
    >
      <ButtonDateRangePicker
        label={`${dayjs(dataDateActivity[0]).format(
          manageFormat(locale)
        )} - ${dayjs(dataDateActivity[1]).format(manageFormat(locale))}`}
        value={[dayjs(dataDateActivity[0]), dayjs(dataDateActivity[1])]}
        onChange={(newValue: DateRange<unknown>) => {
          setActivityDate(newValue as Date[], dataSBFilters, filterMap);
        }}
      />
    </LocalizationProvider>
  );
}
