/**
 * @author David Roman <david@inarix.com>
 * @file Login component
 * @desc Created on 2023-08-17 11:28:44 am
 * @copyright Inarix
 */
import React, { FC, FormEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { State } from "../../redux/slices/index";
import validator from "validator";

import {
  resetState as loginResetState,
  disableErrorLogin,
  setEmailSent,
  setResetPasswordSuccess,
  setNewPassword,
} from "../../redux/slices/authentication/authentication";

import { resetPassword } from "../../redux/actions/authentication/authentication";
import NewInarixIcon from "../../assets/images/Union.svg";

import { Button, IconButton, InputAdornment, TextField } from "@mui/material";

import pjson from "../../../package.json";

import { LoadingCircularProgress } from "../Loading";
import { setLocale } from "../../redux/slices/layout";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { SelectLanguage } from "./Login";

const SetActionsAlpha = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setNewPassword: (newPassword: string) =>
      dispatch(setNewPassword(newPassword)),
  };
};

type LoginFormProps = {
  submitHandler: (e: FormEvent) => void;
  loading: boolean;
  resetPaswordSuccess: boolean;
};
export const managinStrongPasswordMessage = (
  errorInput: boolean,
  newPassword: string,
  t: (arg0: string) => string
) => {
  if (!errorInput && newPassword.length >= 5) {
    return (
      <span className="is-strong-password">{t("Recover_pass.is_strong")}</span>
    );
  }

  if (errorInput && newPassword.length >= 3) {
    return (
      <span className="is-not-strong-password">
        {t("Recover_pass.is_not_strong")}
      </span>
    );
  }
  return;
};

const ActivateAccount: FC<LoginFormProps> = ({
  submitHandler,
  loading,
  resetPaswordSuccess,
}) => {
  const [t] = useTranslation("global");
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorInput, setErrorInput] = React.useState(false);
  const { setNewPassword } = SetActionsAlpha();
  const navigate = useNavigate();

  const { newPassword } = useSelector((state: State) => state.Authentication);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const validate = (value: string) => {
    setNewPassword(value);
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrorInput(false);
      setErrorMessage(t("Recover_pass.is_strong"));
    } else {
      setErrorInput(true);
      setErrorMessage(t("Recover_pass.is_not_strong"));
    }
  };

  if (resetPaswordSuccess) {
    navigate(`/login`);
  }

  return (
    <div className="login-v2-container">
      <div className="login-form">
        <div className="container-info-login-alpha">
          <div className="select-language-div">
            <SelectLanguage />
          </div>
          <div className="inarix-icon-position">
            <img
              data-cy="brand-logo-container"
              src={NewInarixIcon}
              className="inarix-icon-design"
              alt="inarix-icon"
            />

            <div className="form-design">
              <h4 className="title-form-login">{t("Login.Welcome_portal")}</h4>
              <div className="subtitle-form-login">
                {t("Login.activate_account")}
              </div>
              <form className="form-login-data" onSubmit={submitHandler}>
                <TextField
                  fullWidth
                  size="small"
                  type={showPassword ? "text" : "password"}
                  data-cy="password"
                  className="input-user-pass mt-4"
                  label={t("Recover_pass.your_new")}
                  value={newPassword}
                  onChange={(e) => validate(e.currentTarget.value)}
                  error={errorInput}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {!showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
                <div className="password-details-strong">
                  {managinStrongPasswordMessage(errorInput, errorMessage, t)}
                  <span className="info-password">
                    {t("Recover_pass.password_desc")}
                  </span>
                </div>
                <div className="buttons-container-recover">
                  {!loading ? (
                    <Button
                      variant="contained"
                      data-cy="login"
                      type="submit"
                      disabled={errorInput || newPassword.length < 8}
                      className="mt-4 button-recover-pass"
                      style={{ textTransform: "none" }}
                    >
                      {t("Recover_pass.save_log")}
                    </Button>
                  ) : (
                    <LoadingCircularProgress />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="version-position">{pjson.version}</div>
      </div>
      <div className="img-form" />
    </div>
  );
};

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    disableError: () => dispatch(disableErrorLogin()),
    setEmailSent: (setEmail: boolean) => dispatch(setEmailSent(setEmail)),
    activateUser: (token: string, uuid: string) =>
      dispatch(resetPassword({ token, uuid })),
    setLocaleLanguage: (locale: string) => dispatch(setLocale(locale)),
    resetState: () => dispatch(loginResetState()),

    setResetPasswordSuccess: (isSuccess: boolean) =>
      dispatch(setResetPasswordSuccess(isSuccess)),
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Activate = () => {
  const { id } = useParams();
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");

  const { loading, error, errorAuthenticated, resetPaswordSuccess } =
    useSelector((state: State) => state.Authentication);
  const language = localStorage.getItem("i18nextLng");
  const {
    activateUser,
    disableError,
    setLocaleLanguage,
    resetState,
    setEmailSent,
    setResetPasswordSuccess,
  } = SetActions();

  if (error || errorAuthenticated) {
    setTimeout(() => {
      disableError();
    }, 5000);
  }

  if (resetPaswordSuccess) {
    setTimeout(() => {
      setResetPasswordSuccess(false);
    }, 5000);
  }
  useEffect(() => {
    resetState();
    setEmailSent(false);
    setLocaleLanguage(language ?? "fr");
  }, []);

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    activateUser(token as string, id as string);
  };

  return (
    <ActivateAccount
      submitHandler={submitHandler}
      loading={loading}
      resetPaswordSuccess={resetPaswordSuccess}
    />
  );
};

export default Activate;
