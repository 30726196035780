import { FC } from "react";
import { State } from "../../../redux/slices";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  CreateLocationStep,
  setCreateLocationStep,
} from "../../../redux/slices/location/createLocation";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setSteps: (value: CreateLocationStep) => {
      dispatch(setCreateLocationStep(value));
    },
  };
};

export const StepZero: FC<any> = () => {
  const [t] = useTranslation("global");
  const { createLocationStep } = useSelector(
    (state: State) => state.CreateLocationReducer
  );

  const { setSteps } = SetActions();

  return (
    <div className="create-sites-container-steps">
      <div className="title-step-zero">{t("Create_site.How_create")}</div>
      <div
        className="div-cards-step-zero"
        onClick={() =>
          setSteps({ ...createLocationStep, step: 1, option: "a" })
        }
        aria-hidden
      >
        <Card
          sx={{
            display: "flex",
            minWidth: 25,
            justifyContent: "space-between",
            marginTop: "24px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flexDirection: "column" }}>
              <Typography
                component="div"
                sx={{
                  fontSize: "20px",
                }}
              >
                {t("Create_site.Automa")}
              </Typography>
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  color: "text.secondary",
                  fontSize: "14px",
                }}
              >
                {t("Create_site.desc_automa")}
              </Typography>
            </CardContent>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ChevronRightIcon />
          </Box>
        </Card>
      </div>

      <div
        className="div-cards-step-zero"
        onClick={() =>
          setSteps({ ...createLocationStep, step: 1, option: "b" })
        }
        aria-hidden
      >
        <Card
          sx={{
            display: "flex",
            minWidth: 25,
            justifyContent: "space-between",
            marginTop: "24px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography
                component="div"
                sx={{
                  fontSize: "20px",
                }}
              >
                {t("Create_site.Manually")}
              </Typography>
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ color: "text.secondary", fontSize: "14px" }}
              >
                {t("Create_site.desc_manu")}
              </Typography>
            </CardContent>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ChevronRightIcon />
          </Box>
        </Card>
      </div>
      <div className="background-components-fix" />
    </div>
  );
};

export const StepTwo: FC<any> = () => {
  return <div className="create-sites-container-steps"></div>;
};
