import React, { FC, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectLanguage } from "./Login";
import NewInarixIcon from "../../assets/images/Union.svg";
import { useTranslation } from "react-i18next";
import validator from "validator";

import { Button, TextField, IconButton, InputAdornment } from "@mui/material";
import { LoadingCircularProgress } from "../Loading";
import { State } from "../../redux/slices";
import { setNewPassword } from "../../redux/slices/authentication/authentication";
import { resetPassword } from "../../redux/actions/authentication/authentication";
import { ThunkDispatch } from "@reduxjs/toolkit";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { managinStrongPasswordMessage } from "./ActivateAccount";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setNewPassword: (newPassword: string) =>
      dispatch(setNewPassword(newPassword)),
    resetPassword: (token: string, uuid: string) =>
      dispatch(resetPassword({ token, uuid })),
  };
};

const SetNewPassword: FC<any> = () => {
  const [t] = useTranslation("global");
  const { id } = useParams();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  const { setNewPassword, resetPassword } = SetActions();
  const { loading, newPassword, resetPaswordSuccess, error } = useSelector(
    (state: State) => state.Authentication
  );

  const [showPassword, setShowPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorInput, setErrorInput] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    resetPassword(token as string, id as string);
  };

  const validate = (value: string) => {
    setNewPassword(value);
    if (error) {
      setErrorMessage(error);
      setErrorInput(true);
    }
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrorInput(false);
      setErrorMessage(t("Recover_pass.is_strong"));
    } else {
      setErrorInput(true);
      setErrorMessage(t("Recover_pass.is_not_strong"));
    }
  };

  if (resetPaswordSuccess) {
    navigate(`/login`);
  }

  const managingError = () => {
    if (error) {
      return JSON.stringify(error);
    } else {
      return undefined;
    }
  };

  return (
    <div className="reset-password-container">
      <div className="select-language-div-recover">
        <SelectLanguage />
      </div>
      <div className="form-recover-container">
        <div className="form-design-recover">
          <img
            data-cy="brand-logo-container"
            src={NewInarixIcon}
            className="inarix-icon-design"
            alt="inarix-icon"
          />

          <div className="inst-container2">
            <h4 className="title-form-login">{t("Recover_pass.set_new")}</h4>

            <form
              onSubmit={submitHandler}
              className="input-button-recover-container"
            >
              <TextField
                fullWidth
                size="small"
                type={showPassword ? "text" : "password"}
                data-cy="password"
                className="input-user-pass mt-4"
                label={t("Recover_pass.your_new")}
                value={newPassword}
                onChange={(e) => validate(e.currentTarget.value)}
                error={errorInput || !!error}
                helperText={errorMessage || managingError()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
              />
              <div className="password-details-strong">
                {managinStrongPasswordMessage(errorInput, errorMessage, t)}
                <span className="info-password">
                  {t("Recover_pass.password_desc")}
                </span>
              </div>
              <div className="buttons-container-recover">
                {!loading ? (
                  <Button
                    variant="contained"
                    data-cy="login"
                    type="submit"
                    disabled={errorInput || newPassword.length < 8}
                    className="mt-4 button-recover-pass"
                    style={{ textTransform: "none" }}
                  >
                    {t("Recover_pass.save_log")}
                  </Button>
                ) : (
                  <LoadingCircularProgress />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetNewPassword;
