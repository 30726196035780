import React, { useState, useMemo } from "react";
import {
  GridActionsCellItem,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid-pro";
import { DColumns } from "../../../../redux/actions/data/DataDeclarations";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from "@mui/material";
import { State } from "../../../../redux/slices";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CreateLocationsColumnsRows = (
  rawColumns: DColumns[],
  rowModesModel: GridRowModesModel,
  handleSaveClick: (id: GridRowId) => () => void,
  handleCancelClick: (id: GridRowId) => () => void,
  handleEditClick: (id: GridRowId) => () => void,
  handleDeleteClick: (id: GridRowId) => () => void,
  toggleNomad: (id: GridRowId) => () => void,
  handleChange: (event: SelectChangeEvent<any>, id: string) => void,
  handleChangeActions: (event: SelectChangeEvent<any>, id: string) => void
) => {
  const [t] = useTranslation("global");
  const { locations } = useSelector((state: State) => state.MapReducer);
  const { actionsValues, usersConfigRows } = useSelector(
    (state: State) => state.CreateLocationReducer
  );

  const [searchText, setSearchText] = useState("");

  const displayedOptions = useMemo(
    () => locations.filter((option) => containsText(option.name, searchText)),
    [searchText]
  );
  const displayedOptionsActions = useMemo(
    () =>
      actionsValues.filter((option) => containsText(option.name, searchText)),
    [searchText]
  );

  const columnNames = {
    id: t("Data.table.Details"),
    username: t("Create_site.config_users.username"),
    assignedSite: t("Create_site.config_users.site"),
    assignedAction: t("Create_site.config_users.actions"),
    varietyList: t("Create_site.config_users.variety"),
    nomadUser: t("Create_site.config_users.nomad"),
    actions: "actions",
  };

  let columns: any[] = rawColumns.map((column: DColumns) => {
    if (column.field === "assignedSite") {
      return {
        field: column.field,
        headerName: (columnNames as never)[column.field],
        width: 220,
        alwaysHide: column.alwaysHide,
        disableColumnMenu: true,
        sortable: false,
        type: "string",

        renderCell: (params: any) => {
          const nomadUser = params.row.nomadUser;
          const handleRoleChange = (event: any) => {
            handleChange(event, params.id);
          };
          return (
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel
                id="demo-multiple-name-label"
                sx={{ marginLeft: "-3px" }}
              >
                {t("Common_options.select")}
              </InputLabel>
              <Select
                multiple
                value={params?.value?.filter(function (element: any) {
                  return element !== undefined;
                })}
                onChange={handleRoleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((roleId: any) => (
                      <Chip
                        key={roleId}
                        label={locations?.find((e) => e.id === roleId)?.name}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    value={searchText}
                    autoFocus
                    placeholder={t("Common_options.type_to_search")}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
                <MenuItem disabled selected={nomadUser} value={"isNomad"}>
                  <Checkbox checked={nomadUser} />
                  <ListItemText
                    primary={t("Create_site.config_users.nomad_user_specs")}
                  />
                </MenuItem>

                {displayedOptions.map((option, i) => (
                  <MenuItem
                    key={option?.id}
                    value={option?.id}
                    disabled={nomadUser}
                  >
                    <Checkbox
                      value={option?.id}
                      checked={params.value.includes(option?.id)}
                    />
                    {option?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        },
      };
    }

    if (column.field === "assignedAction") {
      return {
        field: column.field,
        headerName: (columnNames as never)[column.field],
        width: 220,
        type: "string",
        sortable: false,
        disableColumnMenu: true,
        alwaysHide: column.alwaysHide,
        renderCell: (params: any) => {
          const handleRoleChange = (event: any) => {
            handleChangeActions(event, params.id);
          };
          return (
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel
                id="demo-multiple-name-label"
                sx={{ marginLeft: "-3px" }}
              >
                {t("Common_options.select")}
              </InputLabel>
              <Select
                multiple
                value={params.value}
                onChange={handleRoleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((roleId: any) => (
                      <Chip
                        key={roleId}
                        label={
                          actionsValues?.find((e) => e.id === roleId)?.name
                        }
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    value={searchText}
                    autoFocus
                    placeholder={t("Common_options.type_to_search")}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>

                {displayedOptionsActions.map((option, i) => (
                  <MenuItem key={option.id} value={option.id}>
                    <Checkbox
                      value={option?.id}
                      checked={params?.value?.includes(option.id)}
                    />
                    {option?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        },
      };
    }

    if (column.field === "nomadUser") {
      return {
        field: column.field,
        headerName: (columnNames as never)[column.field],
        width: 150,
        editable: false,
        type: "boolean",
        sortable: false,
        disableColumnMenu: true,
        alwaysHide: column.alwaysHide,
        renderCell: (params: any) => {
          return (
            <Switch
              checked={params.value}
              onClick={toggleNomad(params?.id)}
              inputProps={{ "aria-label": "controlled" }}
            />
          );
        },
      };
    }

    if (column.field === "actions") {
      return {
        headerName: "",
        field: column.field,
        width: column.width ? column.width : 100,
        hide: !column.show,
        filterable: false,
        sortable: false,
        alwaysHide: column.alwaysHide,
        disableColumnMenu: true,
        cellClassName: "actions",
        renderCell: ({ id }: any) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                key={uuidv4()}
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                key={uuidv4()}
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              key={uuidv4()}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              key={uuidv4()}
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      };
    }

    return {
      field: column.field,
      headerName: (columnNames as never)[column.field],
      width: column.width,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      alwaysHide: column.alwaysHide,
      editable: true,
    };
  });

  let rowsCreateLoc = usersConfigRows?.map((row: any) => {
    const rows = {
      id: row.id,
      username: row.username,
      assignedSite: row.assignedSite,
      assignedAction: row.assignedAction,
      nomadUser: row.nomadUser,
      actions: row.id,
      isNew: false,
    };
    return rows;
  }) as any;

  const columnsFilter = columns?.filter((columns: any) => !columns.alwaysHide);

  return {
    rawColumns: columnsFilter,
    rowsCreateLoc,
  };
};

export default CreateLocationsColumnsRows;
