import * as React from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";

import { useDispatch } from "react-redux";

import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  createNewLocationActive,
  logEventsNewPosition,
  setLatitudeEditCreateLocation,
  setLongitudeEditCreateLocation,
  setNewLocationNewCluster,
  setSidebarViews,
  setTypeOfActionMap,
} from "../../../redux/slices/map/map";
import {
  fetchLocationMapbox,
  fetchOrgGeoPointTypes,
} from "../../../redux/actions/map/mapAsyncActions";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    openCreateLocationPanel: (
      token: string,
      latitude: number,
      longitude: number
    ) => {
      dispatch(createNewLocationActive());
      dispatch(setSidebarViews(false));
      dispatch(fetchOrgGeoPointTypes(token));
      dispatch(setLatitudeEditCreateLocation(latitude));
      dispatch(setLongitudeEditCreateLocation(longitude));
      dispatch(logEventsNewPosition({ lng: longitude, lat: latitude }));
      dispatch(setTypeOfActionMap("createLocation"));
      dispatch(setNewLocationNewCluster(undefined));
      dispatch(fetchLocationMapbox({ text: `${longitude},${latitude}` }));
    },
  };
};

export default function ActionsSpeedDial(
  token: string,
  latitude: number,
  longitude: number,
  t: (arg0: string) => string,
  createLocationActive: boolean
) {
  const { openCreateLocationPanel } = SetActions();

  return (
    <Box>
      {!createLocationActive ? (
        <SpeedDial
          ariaLabel="Portal actions map"
          sx={{ position: "absolute", bottom: "45px", right: "45px" }}
          icon={<SpeedDialIcon />}
          data-cy="add-something"
          onClick={() => openCreateLocationPanel(token, latitude, longitude)}
          //onClick={() => navigate("/create-locations/")}
        />
      ) : (
        ""
      )}
    </Box>
  );
}
