import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../redux/slices";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { StepZero } from "./steps/StepZeroCreateLoc";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  CreateLocationStep,
  resetStateCreatLocUsers,
  setCreateLocationStep,
} from "../../redux/slices/location/createLocation";
import StepTwoOptionB from "./steps/StepOneCreateLocB";
import StepTwoCreateUsers from "./steps/stepTwoCreateUser/StepTwoCreateUser";
import StepTwoNoCreateUsers from "./steps/stepTwoCreateUser/StepTwoNoCreateUser";

import { useNavigate } from "react-router-dom";
import { fetchActionsNamesUsersConfig } from "../../redux/actions/locations/locationsAsyncActions";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setSteps: (value: CreateLocationStep) => {
      dispatch(setCreateLocationStep(value));
    },

    cancel: () => {
      dispatch(resetStateCreatLocUsers());
    },

    fetchActionsNames: (token: string) => {
      dispatch(fetchActionsNamesUsersConfig(token));
    },
  };
};

export const MenuCreateLocation = (
  createLocationStep: CreateLocationStep,
  t: (arg0: string) => string,
  setSteps: (value: CreateLocationStep) => void,
  navigate: any,
  cancel: any
) => {
  const managingTitle = () => {
    if (createLocationStep.step === 0) {
      return t("Create_site.Create_site");
    }
    if (createLocationStep.step === 1) {
      return t("Create_site.Create_site");
    }
    if (
      createLocationStep.step === 2 ||
      (createLocationStep.step === 2 && createLocationStep.option === "b")
    ) {
      return t("Create_site.create_users.add");
    }
  };

  return (
    <div className="top-options-create-locations">
      <div className="spacer-div-back-button">
        {createLocationStep.step === 0 ? (
          <div />
        ) : (
          <Button
            sx={{
              fontSize: "14px",
              textTransform: "none",
              color: "#232128",
            }}
            onClick={() => {
              if (createLocationStep.place !== "configs") {
                setSteps({
                  ...createLocationStep,
                  step: createLocationStep.step - 1,
                });
              }

              if (
                createLocationStep.step === 2 &&
                createLocationStep.option === "b" &&
                createLocationStep.place === "configs"
              ) {
                navigate("/create-locations");
              }
            }}
            startIcon={<ArrowBackIcon />}
            variant="text"
          >
            {t("Common_options.Back")}
          </Button>
        )}
      </div>
      <div className="create-loc-title"> {managingTitle()}</div>
      <div>
        <Button
          sx={{
            fontSize: "14px",
            textTransform: "none",
            color: "#232128",
          }}
          variant="text"
          endIcon={<CloseIcon />}
          onClick={() => {
            navigate("/");
            cancel();
          }}
        >
          {t("Common_options.Cancel")}
        </Button>
      </div>
    </div>
  );
};

const CreateLocation: FC<any> = () => {
  const [t] = useTranslation("global");
  const { setSteps, cancel, fetchActionsNames } = SetActions();
  const { createLocationStep, createNewSite } = useSelector(
    (state: State) => state.CreateLocationReducer
  );
  const { user } = useSelector((state: State) => state.Authentication);
  const token = user?.token ?? "";

  const navigate = useNavigate();

  useEffect(() => {
    fetchActionsNames(token);
  }, []);

  return (
    <div className="create-sites-container">
      {MenuCreateLocation(createLocationStep, t, setSteps, navigate, cancel)}
      {createLocationStep.step === 0 ? <StepZero /> : ""}
      {createLocationStep.step === 1 && createLocationStep.option === "b" ? (
        <StepTwoOptionB />
      ) : (
        ""
      )}
      {createLocationStep.step === 2 &&
      createNewSite.createNewUsers === true ? (
        <StepTwoCreateUsers />
      ) : (
        ""
      )}
      {createLocationStep.step === 2 &&
      createNewSite.createNewUsers === false ? (
        <StepTwoNoCreateUsers />
      ) : (
        ""
      )}
    </div>
  );
};

export default CreateLocation;
