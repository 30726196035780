import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { inarixColors } from "inarix-theme";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  resetActionDetails,
  setSampleId,
} from "../../redux/slices/data/dataSampleView";
import { useNavigate } from "react-router-dom";
import { State } from "../../redux/slices";
import { DataRow } from "../../redux/actions/data/DataDeclarations";
import moment from "moment";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    setIndividualSample: (
      token: string,
      sampleInfo: any,
      pastaActionId: number,
      navigate: any
    ) => {
      dispatch(resetActionDetails());
      dispatch(setSampleId(pastaActionId));
      if (pastaActionId) {
        navigate(`/data/${pastaActionId}`);
      }
    },
  };
};

const TableDataColumns = (t: (arg0: string) => string, token: string) => {
  const { columns, dataSamples } = useSelector(
    (state: State) => state.DataReducer
  );

  const { locale } = useSelector((state: State) => state.LayoutReducer);

  const { setIndividualSample } = SetActions();
  const navigate = useNavigate();

  const columnNames = {
    id: "ID",
    details: t("Data.table.Details"),
  };

  let columnsValues: any[] = columns.map((column: any) => {
    if (column.field === "cerealColor") {
      return {
        headerName: column.field,
        field: column.field,
        width: column.width ? column.width : 100,
        hide: !column.show,
        filterable: false,
        sortable: false,
        show: column.show,
        disableColumnMenu: true,
        alwaysHide: column.alwaysHide,
        isAnnotation: column.isAnnotation,
      };
    }

    if (column.field === "details") {
      return {
        headerName: (columnNames as never)[column.field],
        field: column.field,
        width: 70,
        sortable: false,
        type: "actions",
        show: column.show,
        disableColumnMenu: true,
        alwaysHide: column.alwaysHide,
        isAnnotation: column.isAnnotation,
        renderCell: (params: GridRenderCellParams) => {
          const chipIdDevice = () => {
            const idConsole = () => {
              setIndividualSample(
                token,
                params.row,
                params.row.details as number,
                navigate
              );
            };
            return (
              <div
                style={{
                  borderLeft: `1px solid ${inarixColors.divider_inarix}`,
                  borderRight: `1px solid ${inarixColors.divider_inarix}`,
                  height: "51px",
                }}
              >
                <Tooltip
                  title={t("Fleet_actions.table.Details" as string)}
                  arrow
                >
                  <IconButton
                    sx={{
                      marginTop: "7px",
                      "&:hover": {
                        color: inarixColors.light_inarix,
                      },
                    }}
                    onClick={idConsole}
                    aria-label="delete"
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </div>
            );
          };
          return chipIdDevice();
        },
      };
    }

    if (column.field === "cerealName") {
      return {
        headerName: column.headerName,
        field: column.field,
        width: 240,
        sortable: false,
        show: column.show,
        filterable: false,
        disableColumnMenu: true,
        alwaysHide: column.alwaysHide,
        isAnnotation: column.isAnnotation,
        renderCell: (params: GridRenderCellParams) => {
          const chipIdDevice = () => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "200px",
                  height: "51px",
                  alignItems: "center",
                }}
              >
                <Chip
                  size="small"
                  sx={{ background: params?.row?.cerealColor }}
                  label={params.value}
                />
              </div>
            );
          };
          return chipIdDevice();
        },
      };
    }

    return {
      field: column.field,
      headerName: column.headerName,
      width: column.width,
      sortable: false,
      show: column.show,
      disableColumnMenu: true,
      filterable: false,
      alwaysHide: column.alwaysHide,
      isAnnotation: column.isAnnotation,
    } as any;
  });

  let actionSamples = dataSamples?.data?.map((action: DataRow) => {
    const createdAt = moment(action?.pastActionCreationDate);
    createdAt.locale(locale);
    const createdAtModify = createdAt.format("l, LT");

    const device = () => {
      if (!action.deviceName) {
        return t("Data.table.No_device");
      }
      if (!action.deviceName) {
        return t("Fleet_users_devices.table.Empty_device");
      } else {
        return action.deviceName;
      }
    };

    const location = () => {
      if (!action.geoPointName) {
        return t("Data.table.No_geo_point");
      } else {
        return action.geoPointName;
      }
    };

    // build row generically based on fetched columns
    const row = columnsValues.reduce((obj: any, col: any) => {
      if (col.field === "orgDeviceId") {
        obj[col.field] = device();
      } else if (col.field === "geoPointName") {
        obj[col.field] = location();
      } else if (col.field === "pastActionCreationDate") {
        obj[col.field] = createdAtModify;
      } else if (col.field === "actionId") {
        // do nothing
      } else {
        obj[col.field] = (action as any)[col.field];
      }
      return obj;
    }, {});

    // Set mandatory columns by data table
    row["id"] = action.pastActionId;
    row["details"] = action.pastActionId;

    return row;
  });

  const columnsFilter = columnsValues?.filter(
    (columns: any) => !columns.alwaysHide
  );

  const columnSwitchesValues =
    columnsFilter[0] !== undefined
      ? columnsFilter?.map((col: any) => ({
          name: col.headerName ? col.headerName : col.field,
          id: col.field,
          isSelected: col.show,
        }))
      : [
          {
            name: "",
            id: "",
            isSelected: false,
          },
        ];

  if (!columnsValues) {
    columnsValues = [];
  }

  if (!actionSamples) {
    actionSamples = [];
  }

  return {
    columnSwitchesValues,
    columnsValues: columnsFilter,
    actionSamples,
  };
};

export default TableDataColumns;
