import React, { FC, useEffect } from "react";
import { State } from "../../../redux/slices";
import { useDispatch, useSelector } from "react-redux";
import NotFound from "../../NotFound";
import Layout from "../../layout/Layout";
import DataSampleViewLeft from "./DataSampleLeftSide";
import DataSampleViewRight from "./DataSampleRightSide";
import { Button, Modal } from "@mui/material";
import { BodyCarouselPhotos } from "./DataSampleViewCarouselModal";
import {
  initialState,
  setAnnotationValuesToEdit,
  setModalEditAnnotation,
  setOpenCloseModalPhotos,
  setTableOptionsNextPrev,
} from "../../../redux/slices/data/dataSampleView";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { sampleDetails } from "../../../redux/actions/data/dataSampleViewAsyncActions";
import { BodyEditDeleteAnnotation } from "./DataSampleViewAnnotationModal";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { goBackFunction } from "../dataHelpers/DataProgress";
import { BodyLoadingSampleViewModal } from "./DataSampleViewLoadingModal";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  fetchSamples,
  fetchSamplesActions,
} from "../../../redux/actions/data/dataAsyncActions";

import { setTableDataOptions } from "../../../redux/slices/data/data";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    disableErrorActionView: (navigate: any) => {
      navigate(`/data`);
    },

    setOpenCloseModalPhotos: (visible: boolean) => {
      dispatch(setOpenCloseModalPhotos(visible));
    },
    fetchSampleDetails: (token: string, locale: string, pastAction: string) => {
      dispatch(sampleDetails({ token, locale, pastAction }));
    },

    setModalEditAnnotation: (value: any) => {
      dispatch(setAnnotationValuesToEdit(value));
    },

    closeModal: () => {
      dispatch(setModalEditAnnotation(false));
    },

    fetchSamplesNext: (token: string, page: number, rowOption: number) => {
      dispatch(fetchSamplesActions({ token, page, rowOption }));
    },

    setTableValuesNextPrev: (tableOptionsNextPrev: any) => {
      dispatch(setTableOptionsNextPrev(tableOptionsNextPrev));
    },

    setNewPage: (
      tableOptions: {
        page: number;
        rowOptions: number;
        totalRows: number | null;
      },

      token: string
    ) => {
      dispatch(setTableDataOptions(tableOptions));
      dispatch(fetchSamples({ token }));
    },
  };
};

const DataSampleView: FC = () => {
  const [t] = useTranslation("global");
  const { id } = useParams();
  const bodyModalLoadingSampleView = BodyLoadingSampleViewModal();
  const navigate = useNavigate();
  const { user, isViewerOnly } = useSelector(
    (state: State) => state.Authentication
  );
  const token = user?.token ?? "";
  const {
    sampleViewInfo,
    modalPhotos,
    modalEditAnnotation,
    error,
    loadSampleDetails,
    pastActionIdsNextPrev,
  } = useSelector((state: State) => state.DataSampleViewReducer);

  const { locale } = useSelector((state: State) => state.LayoutReducer);
  const { tableOptions } = useSelector((state: State) => state.DataReducer);

  const bodyCarouselPhotosValues = BodyCarouselPhotos();
  const bodyEditDeleteAnnotation = BodyEditDeleteAnnotation(t, token);

  const [run, setRun] = React.useState(false);

  const {
    setOpenCloseModalPhotos,
    fetchSampleDetails,
    setModalEditAnnotation,
    closeModal,
    disableErrorActionView,
    setNewPage,
  } = SetActions();

  const showError = () => {
    disableErrorActionView(navigate);
  };

  if (error) {
    showError();
  }
  const resetEdit = () => {
    closeModal();
    setModalEditAnnotation(initialState.annotationSelectedValuesToEdit);
  };

  const getPrevAndNext = (activeID: string): any => {
    const index = pastActionIdsNextPrev?.findIndex((a: string) => {
      return a === activeID;
    });

    const prev = pastActionIdsNextPrev[index - 1];
    if (!prev) {
      return {
        indexOf: index,
        nextPrev: [undefined, pastActionIdsNextPrev[index + 1]],
      };
    }

    const next = pastActionIdsNextPrev[index + 1];
    if (!next) {
      return {
        indexOf: index,
        nextPrev: [pastActionIdsNextPrev[index - 1], "null"],
      };
    }
    if (index === -1) {
      return [undefined, next];
    }

    return {
      indexOf: index,
      nextPrev: [prev, next],
    };
  };

  const Next = getPrevAndNext(id as string)?.nextPrev[1];
  const Prev = getPrevAndNext(id as string)?.nextPrev[0];
  const index = getPrevAndNext(id as string).indexOf + 1;

  useEffect(() => {
    fetchSampleDetails(token, locale, id as string);

    if (run) {
      setTimeout(() => {
        setRun(true);

        if (Next === "null") {
          setNewPage({ ...tableOptions, page: tableOptions.page + 1 }, token);
        }

        setTimeout(() => {
          setRun(false);
        }, 1000);
      }, 3000);
    } else {
      return () => {};
    }
  }, [run]);

  const headerInfo = () => {
    return (
      <div className="sample-header">
        {goBackFunction(
          navigate,
          t,
          "Data.title",
          `/data`,
          "Data.table.Action"
        )}
        <div className="sample-info-container">
          <div>
            <div className="sample-id-title">{`${t(
              "Data.table.Action"
            )} ${sampleViewInfo?.id?.slice(0, 8)}`}</div>
            <div className="sample-inarix-id-title">{sampleViewInfo?.id}</div>
          </div>
          <div className="action-buttons-action-view-go">
            {Prev !== undefined ? (
              <Button
                sx={{ textTransform: "none", color: "#3e3a42" }}
                variant="text"
                startIcon={<KeyboardArrowLeftIcon />}
                onClick={() => {
                  navigate(`/data/${getPrevAndNext(id as string).nextPrev[0]}`);
                  setRun(true);
                }}
              >
                {t("Data.SampleView.prev_action")}
              </Button>
            ) : (
              ""
            )}
            {Next !== undefined ? (
              <Button
                sx={{ textTransform: "none", color: "#3e3a42" }}
                variant="text"
                endIcon={<KeyboardArrowRightIcon />}
                disabled={index === tableOptions.totalRows}
                onClick={() => {
                  if (Next !== "null") {
                    navigate(
                      `/data/${getPrevAndNext(id as string).nextPrev[1]}`
                    );
                    setRun(true);
                  }
                  if (Next === "null") {
                    setNewPage(
                      { ...tableOptions, page: tableOptions.page + 1 },
                      token
                    );
                  }
                }}
              >
                {t("Data.SampleView.next_action")}
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  };

  const globalComponentsSampleView = () => (
    <div>
      <div className="sample-view-container">
        {headerInfo()}
        <div className="left-right-contain">
          <DataSampleViewLeft />
          <DataSampleViewRight />
        </div>
      </div>
      <Modal open={modalPhotos} onClose={() => setOpenCloseModalPhotos(false)}>
        {bodyCarouselPhotosValues}
      </Modal>
      <Modal open={modalEditAnnotation} onClose={resetEdit}>
        {bodyEditDeleteAnnotation}
      </Modal>

      <Modal open={loadSampleDetails}>{bodyModalLoadingSampleView}</Modal>
    </div>
  );

  return (
    <Layout
      title={""}
      component={!isViewerOnly ? globalComponentsSampleView() : <NotFound />}
    />
  );
};

export default DataSampleView;
