import { Box, Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import {
  DataGridPro,
  GridFooterContainer,
  GridPagination,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import TopDataPannel from "./DataTopPanel";
import themeInarix from "../StylesMuiInarix";
import { State } from "../../redux/slices";
import { useDispatch, useSelector } from "react-redux";
import tableDataColumns from "./DataFetchingColumns";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { setColumns, setTableDataOptions } from "../../redux/slices/data/data";
import { fetchSamples } from "../../redux/actions/data/dataAsyncActions";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  return {
    fetchNewPage: (token: string, page: number, rowOption: number) =>
      dispatch(fetchSamples({ token, page, rowOption })),
    setColumnIndex: (columns: any) => dispatch(setColumns(columns)),
    setNewPage: (
      tableOptions: {
        page: number;
        rowOptions: number;
        totalRows: number | null;
      },
      page: number,
      rowOptions: number,
      token: string,
      prefetchOption: number
    ) => {
      dispatch(setTableDataOptions({ ...tableOptions, page, rowOptions }));
      dispatch(fetchSamples({ token }));
    },
  };
};

export function DataTable(
  t: (arg0: string) => string,
  token: string,
  locale: string
) {
  const apiRef = useGridApiRef();
  const { setNewPage, setColumnIndex } = SetActions();

  const { columnsVisibility, tableOptions, dataSamplesLoading } = useSelector(
    (state: State) => state.DataReducer
  );

  const { prefetchPages } = useSelector(
    (state: State) => state.DataSampleViewReducer
  );

  const { columnsValues, actionSamples } = tableDataColumns(t, token);

  const paginationModel = {
    pageSize: tableOptions.rowOptions,
    page: tableOptions.page,
  };

  const handlePageChange = (page: number, rowOption: number) => {
    setNewPage(tableOptions, page, rowOption, token, prefetchPages);
  };

  const paginationToolbar = () => {
    return (
      <GridFooterContainer sx={{ alignItems: "baseline" }}>
        <div className="grid-foot-container">
          {tableOptions.page > 0 ? (
            <Button
              variant="contained"
              onClick={() => handlePageChange(0, tableOptions.rowOptions)}
              disabled={dataSamplesLoading || tableOptions.page < 1}
            >
              {t("Data.table.Restart_page")}
            </Button>
          ) : (
            ""
          )}
          <GridPagination />
        </div>
      </GridFooterContainer>
    );
  };

  return (
    <Box sx={{ height: 500, width: 1, marginLeft: "40px" }}>
      {TopDataPannel(t, token, apiRef)}
      <ThemeProvider theme={themeInarix(locale)}>
        <DataGridPro
          loading={dataSamplesLoading}
          className="table-styles-data"
          columns={
            columnsValues[0] !== undefined
              ? columnsValues
              : [
                  {
                    headerName: "ID",
                    field: "id",
                    width: 100,
                    hide: false,
                    filterable: false,
                    sortable: false,
                    show: true,
                    disableColumnMenu: true,
                    alwaysHide: true,
                  },
                ]
          }
          onColumnOrderChange={() => {
            setColumnIndex(apiRef.current.getAllColumns());
          }}
          onColumnWidthChange={() =>
            setColumnIndex(apiRef.current.getAllColumns())
          }
          rows={actionSamples}
          paginationMode="server"
          filterMode="server"
          columnVisibilityModel={columnsVisibility}
          paginationModel={paginationModel}
          slots={{ footer: paginationToolbar }}
          onPaginationModelChange={(newPage) => {
            handlePageChange(newPage.page, newPage.pageSize);
          }}
          rowCount={tableOptions.totalRows ? tableOptions.totalRows : 0}
          initialState={{
            pagination: {
              paginationModel: { pageSize: tableOptions.rowOptions },
            },
          }}
          pagination
          pageSizeOptions={[25, 50, 100]}
          apiRef={apiRef}
        />
      </ThemeProvider>
    </Box>
  );
}
