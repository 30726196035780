import React, { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Layout from "../layout/Layout";
import { State } from "../../redux/slices";
import { setTabNameLayout } from "../../redux/slices/layout";

import { ThunkDispatch } from "@reduxjs/toolkit";

import {
  DataSBFilters,
  DataTableRow,
  disableErrorsDataTable,
  initialState,
  setColumnVisibilityModel,
  setColumnsSearch,
  setDateDataActivity,
  setExportModalValues,
  setExportModalVisibility,
  setExtraFiltersValuesAutocomplete,
  setSBExtraFiltersVisible,
} from "../../redux/slices/data/data";

import { Modal } from "@mui/material";

import { DataTable } from "./DataTable";
import SidebarDataFilters from "./dataExtraFilters/DataExtraFiltersSB";
import { BodyExportModal } from "./dataModals/DataExportModal";
import CircularProgressWithLabel, {
  FiltersDataTableObject,
} from "./dataHelpers/DataProgress";
import { GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import {
  fetchCropsService,
  fetchSamples,
  getUserViews,
} from "../../redux/actions/data/dataAsyncActions";
import { errorsGeneralModal } from "../ErrorModal";
import { disableErrors } from "../../redux/slices/data/dataSampleView";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    fetchViews: (token: string) => dispatch(getUserViews(token)),
    resetDataTableSection: (_: string, sampleViewInfo: any) => {
      if (!sampleViewInfo) {
        dispatch(setTabNameLayout("/data"));
        dispatch(setExportModalValues(initialState.dataExportModalValues));
        dispatch(setExportModalVisibility(false));
        dispatch(setColumnsSearch(""));
        dispatch(setExtraFiltersValuesAutocomplete(initialState.dataSBFilters));
        dispatch(setSBExtraFiltersVisible(false));
      }
    },
    setExportModal: (visible: boolean) => {
      dispatch(setExportModalVisibility(visible));
      dispatch(setExportModalValues(initialState.dataExportModalValues));
    },

    setVisibleSBExtraFilters: (visible: boolean) => {
      dispatch(setSBExtraFiltersVisible(visible));
    },

    setColumnVModel: (
      model: GridColumnVisibilityModel,
      sampleViewInfo: any
    ) => {
      if (!sampleViewInfo) {
        dispatch(setColumnVisibilityModel(model));
      }
    },

    fetchSamplesData: (
      token: string,
      sampleViewInfo: any,
      isRenderingSwitches: boolean
    ) => {
      if (!sampleViewInfo) {
        dispatch(fetchSamples({ token }));

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !isRenderingSwitches
          ? dispatch(fetchCropsService({ token }))
          : () => {};
      }
    },

    setActivityDate: (
      date: Date[],
      dataSBFilters: DataSBFilters,
      map: Record<keyof DataTableRow, keyof DataTableRow>
    ) => {
      dispatch(setDateDataActivity(date));
      dispatch(
        setExtraFiltersValuesAutocomplete({
          ...dataSBFilters,
          filterDate1: FiltersDataTableObject(
            map,
            "pastActionCreationDate",
            date as any,
            "<"
          ),
        })
      );
    },

    disableError: () => {
      dispatch(disableErrors());
      dispatch(disableErrorsDataTable());
    },

    setExportValues: (dataExportModalValues: {
      email: string;
      typeOfExport: string;
      typeOfExportSelected: string;
    }) => {
      dispatch(setExportModalValues(dataExportModalValues));
    },
  };
};

interface CountdownProps {
  seconds: number;
}

export function CountdownLoaderExport({
  seconds,
}: Readonly<CountdownProps>): JSX.Element {
  const { dataExportModalValues } = useSelector(
    (state: State) => state.DataReducer
  );
  const { setExportValues } = SetActions();

  const [timeLeft, setTimeLeft] = React.useState(seconds);
  const intervalRef = React.useRef<NodeJS.Timer>();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimeLeft((t) => (t >= 100 ? 0 : t + 10.2));
    }, 1000);
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (timeLeft <= 0) {
      clearInterval(intervalRef.current);

      setExportValues({
        ...dataExportModalValues,
        typeOfExportSelected: "download-step2",
      });

      intervalRef.current = setInterval(() => {
        setTimeLeft((t) => (t >= 100 ? 0 : t + 1.2));
      }, 1000);
    }
  }, [timeLeft]);

  return <CircularProgressWithLabel value={timeLeft} />;
}

const Data: FC = () => {
  const [t] = useTranslation("global");
  const {
    setExportModal,
    setColumnVModel,
    fetchSamplesData,
    disableError,
    setVisibleSBExtraFilters,
    fetchViews,
  } = SetActions();
  const { user } = useSelector((state: State) => state.Authentication);

  const { locale } = useSelector((state: State) => state.LayoutReducer);
  const {
    dataExportModalVisible,
    dataExportModalValues,
    columns,
    tableOptions,
    errorDataTable,
    dataExtraFiltersSBVisible,
    comesFromPageNot0,
    isRenderingSwitches,
  } = useSelector((state: State) => state.DataReducer);

  const { error, sampleViewInfo } = useSelector(
    (state: State) => state.DataSampleViewReducer
  );
  const token = user?.token ?? "";

  const BodyExportModalContent = BodyExportModal(
    token,
    false,
    tableOptions.totalRows ? tableOptions.totalRows : 0,
    setExportModal
  );

  const ErrorToJSon = JSON.stringify(error || errorDataTable);

  const BodyErrors = errorsGeneralModal(ErrorToJSon, disableError, t);

  const columnsVisibilityModelTranform = columns.map((col: any) => {
    const visibility = {
      col: col.field,
      show: col.show,
    };
    return visibility;
  });

  const columnsVisibilityModel = columnsVisibilityModelTranform.reduce(
    (obj, item) => Object.assign(obj, { [item.col]: item.show }),
    {}
  );
  useEffect(() => {
    {
      if (!comesFromPageNot0) {
        setColumnVModel(columnsVisibilityModel, sampleViewInfo);
      }
    }

    fetchViews(token);
    fetchSamplesData(token, sampleViewInfo, isRenderingSwitches);
  }, []);

  const globalComponentsData = () => (
    <div style={{ width: "96%" }}>
      <div className="fleet-table-container-data">
        <div
          role="none"
          onClick={
            dataExtraFiltersSBVisible
              ? () => setVisibleSBExtraFilters(false)
              : () => {}
          }
        >
          {DataTable(t, token, locale)}
        </div>

        <div className="data-table-sidebar-styles">
          {SidebarDataFilters(t, token, locale)}
        </div>
      </div>

      <Modal
        open={dataExportModalVisible}
        onClose={
          dataExportModalValues.typeOfExportSelected.length === 0
            ? () => setExportModal(false)
            : () => undefined
        }
      >
        {BodyExportModalContent}
      </Modal>
      <Modal open={ErrorToJSon?.length > 3}>{BodyErrors}</Modal>
    </div>
  );

  return <Layout title={t("Data.title")} component={globalComponentsData()} />;
};

export default Data;
