/**
 * @author David Roman <david@inarix.com>
 * @file Signin Component
 * @desc Created on 2023-09-17 12:00:44 pm
 * @copyright Inarix
 */

import axios from "axios";
import { API } from "../../../config";
import { State } from "../../slices";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { isViewerOnlyInfo } from "../../slices/authentication/authentication";
import { RootState } from "../../../configureStore";

import { requestAccessUrl, userLoginUrl } from "../../utils/queries";

export interface User {
  token: string;
}

export interface TokenPayload {
  userId?: string;
  tokenId?: number;
  realmId: number;
  orgIds: number[];
  isSuperAdmin: boolean;
  expires: number;
  isTPToken: boolean;
}

export const haveAccess = createAsyncThunk(
  "AuthReducer/haveAccess",
  async (arg: { token: string; locale: string }, store) => {
    try {
      const response = await axios.get(requestAccessUrl, {
        headers: {
          Authorization: `Bearer ${arg.token}`,
          "Accept-Language": `${arg.locale}`,
        },
      });
      const { data } = response;

      store.dispatch(
        isViewerOnlyInfo(data?.isViewerOnly ? data?.isViewerOnly : false)
      );
      return store.fulfillWithValue(data);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const haveAccess2 = async (
  token: string,
  locale: string
): Promise<any> => {
  const res = await axios({
    method: "GET",
    url: requestAccessUrl,

    headers: {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = res;
  return data;
};

export const signIn = createAsyncThunk(
  "AuthReducer/signIn",
  async (_: null | undefined, store) => {
    const { username, password } = (store.getState() as RootState)
      .Authentication;
    const { locale } = (store.getState() as State).LayoutReducer;

    try {
      const response = await axios.post(
        userLoginUrl,
        { username, password },
        {
          headers: {
            "Accept-Language": `${locale}`,
          },
        }
      );
      const { data } = response;

      store.dispatch(haveAccess({ token: data.token, locale }));
      await haveAccess2(data.token, locale);

      return store.fulfillWithValue(data.token);
    } catch (error: any) {
      return store.rejectWithValue(error.response.data.message);
    }
  }
);

export const recoverPassword = createAsyncThunk(
  "AuthReducer/recoverPassword",
  async (_: null | undefined, store) => {
    const { recoverEmail } = (store.getState() as RootState).Authentication;

    try {
      const response = await axios.post(`${API}/users/request-password`, {
        input: recoverEmail,
      });
      const { data } = response;
      return store.fulfillWithValue(data);
    } catch (error: any) {
      return store.rejectWithValue(error);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "AuthReducer/resetPassword",
  async (arg: { token: string; uuid: string }, store) => {
    const { newPassword } = (store.getState() as RootState).Authentication;
    const { locale } = (store.getState() as RootState).LayoutReducer;

    try {
      const response = await axios.patch(
        `${API}/users/request-password/${arg.uuid}`,
        { password: newPassword, token: arg.token },
        { headers: { "Accept-Language": `${locale}` } }
      );

      const { data } = response;
      // if (data.success) {
      //   store.dispatch(signInFromResetActivatePassword({}))
      // }

      return store.fulfillWithValue(data);
    } catch (error: any) {
      return store.rejectWithValue(error);
    }
  }
);

export const refreshToken = createAsyncThunk(
  "AuthReducer/refreshToken",
  async (_: null | undefined, store) => {
    const { user } = (store.getState() as State).Authentication;
    const { locale } = (store.getState() as State).LayoutReducer;
    const headers = {
      Authorization: `Bearer ${user?.token}`,
      "Accept-Language": `${locale}`,
    };

    try {
      const response = await axios.put(
        `${API}/users/refresh-token/`,
        {},
        { headers }
      );
      const { data } = response;
      return store.fulfillWithValue(data?.token);
    } catch (error: any) {
      return store.rejectWithValue(error);
    }
  }
);

export const autoRefreshToken = createAsyncThunk(
  "AuthReducer/autoRefreshToken",
  async (_: null | undefined, store) => {
    const { refreshTimeoutId } = (store.getState() as State).Authentication;

    if (typeof refreshTimeoutId === "number") {
      window.clearTimeout(refreshTimeoutId);
    }

    return store.dispatch(refreshToken());
  }
);
