import { createSlice } from "@reduxjs/toolkit";
import {
  checkAnnotationsPermissions,
  sampleDetails,
} from "../../actions/data/dataSampleViewAsyncActions";
export type AnnotationSelectedValuesToEdit = {
  id: string;
  step: number;
  reasonToDeleteUpdate: string;
  valueSelectedToUpdate: string;
  editAnnotation: boolean;
  deleteAnnotation: boolean;
  values: any[];
  newFileAnnotationEdit: any;
};

export interface DataSampleviewState {
  sampleViewInfo: any;
  sampleId: string;
  modalPhotos: boolean;
  loadSampleDetails: boolean;
  photos: any;
  modalEditAnnotation: boolean;
  pemissionAnnotationLoading: boolean;
  annotationSelectedValuesToEdit: AnnotationSelectedValuesToEdit;
  annotationSelectedToEdit: any;
  permissionCheckAnnotation: boolean;
  error: string;
  idsPreviousNextActions: any;
  indexOF: number;
  tableOptionsNextPrev: {
    page: number;
    rowOptions: number;
    totalRows: number;
  };
  arrayPages: number[];
  prefetchPages: number;
  pastActionIdsNextPrev: string[];
}

export const initialState: DataSampleviewState = {
  sampleViewInfo: null,
  sampleId: "",
  modalPhotos: false,
  loadSampleDetails: false,
  photos: [],
  modalEditAnnotation: false,
  pemissionAnnotationLoading: false,
  permissionCheckAnnotation: false,
  annotationSelectedValuesToEdit: {
    id: "",
    step: 0,
    reasonToDeleteUpdate: "",
    editAnnotation: false,
    deleteAnnotation: false,
    valueSelectedToUpdate: "",
    values: [],
    newFileAnnotationEdit: undefined,
  },
  annotationSelectedToEdit: undefined,
  error: "",
  idsPreviousNextActions: {},
  indexOF: 0,
  tableOptionsNextPrev: {
    page: 0,
    rowOptions: 0,
    totalRows: 0,
  },
  arrayPages: [0],
  prefetchPages: 3,
  pastActionIdsNextPrev: [""],
};

export const DataSampleViewSlice = createSlice({
  name: "DataSampleViewReducer",
  initialState,
  reducers: {
    setPrefetchPages(state, action) {
      return {
        ...state,
        prefetchPages: action.payload,
      };
    },

    setPastActionIds(state, action) {
      return {
        ...state,
        pastActionIdsNextPrev: action.payload,
      };
    },
    setArrayPages(state, action) {
      return {
        ...state,
        arrayPages: action.payload,
      };
    },
    setTableOptionsNextPrev(state, action) {
      return {
        ...state,
        tableOptionsNextPrev: action.payload,
      };
    },
    setIdsPreviousNext(state, action) {
      return {
        ...state,
        idsPreviousNextActions: action.payload,
      };
    },

    disableErrors(state) {
      return { ...state, error: "" };
    },

    setAnnotationValuesToEdit(state, action) {
      return { ...state, annotationSelectedValuesToEdit: action.payload };
    },
    setAnnotationToEdit(state, action) {
      return { ...state, annotationSelectedToEdit: action.payload };
    },
    setModalEditAnnotation(state, action) {
      return { ...state, modalEditAnnotation: action.payload };
    },
    setSampleViewInfo(state, action) {
      return { ...state, sampleViewInfo: action.payload };
    },
    setSampleId(state, action) {
      return { ...state, sampleId: action.payload };
    },

    setOpenCloseModalPhotos(state, action) {
      return { ...state, modalPhotos: action.payload };
    },

    setSampleViewPhotos(state, action) {
      return { ...state, photos: action.payload };
    },

    resetActionDetails(state) {
      return {
        ...state,
        sampleViewInfo: null,
        sampleId: "",
        modalPhotos: false,
        loadSampleDetails: false,
        photos: [],
        modalEditAnnotation: false,
        pemissionAnnotationLoading: false,
        permissionCheckAnnotation: false,
        annotationSelectedValuesToEdit: {
          id: "",
          step: 0,
          reasonToDeleteUpdate: "",
          editAnnotation: false,
          deleteAnnotation: false,
          valueSelectedToUpdate: "",
          values: [],
          newFileAnnotationEdit: undefined,
        },
        annotationSelectedToEdit: undefined,
        error: "",
      };
    },

    logOutActionDetails() {
      return initialState;
    },
  },

  extraReducers(builder) {
    builder.addCase(sampleDetails.pending, (state) => {
      state.loadSampleDetails = true;
    });

    builder.addCase(sampleDetails.fulfilled, (state, action) => {
      state.loadSampleDetails = false;
      state.sampleViewInfo = action.payload;
    });

    builder.addCase(sampleDetails.rejected, (state, action) => {
      state.loadSampleDetails = false;
      state.error = action.payload as any;
      state.photos = [];
    });

    builder.addCase(checkAnnotationsPermissions.pending, (state) => {
      state.pemissionAnnotationLoading = true;
      state.permissionCheckAnnotation = false;
    });

    builder.addCase(checkAnnotationsPermissions.fulfilled, (state) => {
      state.pemissionAnnotationLoading = false;
      state.permissionCheckAnnotation = true;
    });

    builder.addCase(checkAnnotationsPermissions.rejected, (state, action) => {
      state.pemissionAnnotationLoading = false;
      state.permissionCheckAnnotation = false;
      state.error = action.payload as any;
    });
  },
});

export const {
  setPrefetchPages,
  setPastActionIds,
  setArrayPages,
  setTableOptionsNextPrev,
  logOutActionDetails,
  setIdsPreviousNext,
  resetActionDetails,
  disableErrors,
  setAnnotationToEdit,
  setAnnotationValuesToEdit,
  setModalEditAnnotation,
  setSampleViewInfo,
  setSampleId,
  setOpenCloseModalPhotos,
  setSampleViewPhotos,
} = DataSampleViewSlice.actions;

export const DataSampleView = DataSampleViewSlice.reducer;
