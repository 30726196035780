import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../configureStore";
import { API } from "../../../config";
import {
  ColumnSwitchesTypes,
  isRenderingActionFromViews,
  setColumnVisibilityModel,
  setColumns,
  setComesfromPageNot0,
  setExportModalValues,
  setExtraFiltersValuesAutocomplete,
  setFilterMapValues,
  setTableDataOptions,
  setViewsValues,
} from "../../slices/data/data";
import { Column } from "./DataDeclarations";

import {
  changePosInArray,
  FiltersDataTableObject,
} from "../../../components/data/dataHelpers/DataProgress";
import { addHours, setZeroHrs } from "../dashboard/dashboardAsyncActions";
import {
  setPastActionIds,
  setTableOptionsNextPrev,
} from "../../slices/data/dataSampleView";

export const fetchCropsService = createAsyncThunk(
  "DataReducer/fetchCropsService",
  async (arg: { token: string }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { dataSBFilters, filterMap, dataDateActivity } = (
      store.getState() as RootState
    ).DataReducer;
    const headers = {
      Authorization: `Bearer ${arg.token}`,
      "Accept-Language": `${locale}`,
    };

    try {
      const response = await axios.get(
        `${API}/core/scenario?$joinEager=[cerealType.text.[localizedTexts.[locale]]]&$select[]=cerealType:text:localizedTexts:locale.value&cerealType:text:localizedTexts:locale.value=${locale}`,
        {
          headers,
        }
      );
      const { data } = response;
      const dictionnary = data?.data?.reduce((obj: any, current: any) => {
        if (!obj[current.cerealType.slug]) {
          obj[current.cerealType.slug] = {
            id: current.cerealType.id,
            name: current.cerealType.text.localizedTexts[0].translation,
            isSelected: false,
          };
        }
        return obj;
      }, {});

      const filtered = Object.keys(dictionnary).map((key) => dictionnary[key]);
      store.dispatch(
        setExtraFiltersValuesAutocomplete({
          ...dataSBFilters,
          cropsSelected: filtered,
          filterDate1: FiltersDataTableObject(
            filterMap,
            "pastActionCreationDate",
            dataDateActivity as any,
            "<"
          ),
        })
      );
      return store.fulfillWithValue(filtered);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const fetchSamples = createAsyncThunk(
  "DataReducer/fetchSamples",
  async (arg: { token: string; page?: number; rowOption?: number }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const {
      detailsColumn,
      idColumn,
      tableOptions,
      dataSBFilters,
      dataDateActivity,
      comesFromPageNot0,
      itRendersFromViews,
    } = (store.getState() as RootState).DataReducer;

    const { filtersCrops, filtersLocation, filterActions, filterUsers } =
      dataSBFilters;

    const filterDate = {
      column: "pastActionCreationDate",
      operator: ">",
      value: setZeroHrs(dataDateActivity[0]).toISOString().slice(0, -1),
    };

    const filterDate2 = {
      column: "pastActionCreationDate",
      operator: "<",
      value: addHours(setZeroHrs(dataDateActivity[1]))
        .toISOString()
        .slice(0, -1),
    };

    const filters = [
      filtersCrops,
      filtersLocation,
      filterActions,
      filterUsers,
      filterDate,
      filterDate2,
    ];

    const filtersCount = [
      filtersCrops,
      filtersLocation,
      filterActions,
      filterUsers,
    ];

    const filtersCounts2 = filtersCount.filter(function (element) {
      return element !== undefined;
    });
    const filters2 = filters.filter(function (element) {
      return element !== undefined;
    });

    const myNums = filtersCounts2.map((d: any) => d?.value.length);
    let sum = 0;
    myNums.forEach((num) => {
      sum += num;
    });

    store.dispatch(
      setExtraFiltersValuesAutocomplete({
        ...dataSBFilters,
        filtersSelectedAfterFetch: sum,
      })
    );

    const manageSkip = () => {
      if (tableOptions.page === 0) {
        return;
      } else {
        return `$skip=${tableOptions.page * tableOptions.rowOptions}`;
      }
    };
    const params = [
      manageSkip(),
      `$limit=${tableOptions.rowOptions}`,
      "$sort=[createdAt]=-0",
    ];
    const add = `&`;
    const body = {
      search: dataSBFilters.inputSearch,
      withLocalization: true,
      filtersOp: "and",
      filters: filters2,
    };
    const headers = {
      Authorization: `Bearer ${arg.token}`,
      "Accept-Language": `${locale}`,
    };
    try {
      const response = await axios.post(
        `${API}/samples/new-data-overview?${params.join(add)}`,
        body,
        {
          headers,
        }
      );
      const { data } = response;

      function arra(sum: any, length: number) {
        let array = [];
        for (let i = 0; i < length; i++) {
          array.push(i);
        }
        return array;
      }

      const pagesToPreFetch = arra(tableOptions.page, tableOptions.page + 2);
      console.log(pagesToPreFetch);
      store.dispatch(fetchDataArrays({ arrayPages: pagesToPreFetch }));

      if (tableOptions.page === 0) {
        store.dispatch(setFilterMapValues(data.filterMap));
        store.dispatch(
          setTableDataOptions({ ...tableOptions, totalRows: data.total })
        );
        store.dispatch(
          setTableOptionsNextPrev({ ...tableOptions, totalRows: data.total })
        );

        const columns = data.columns.map((columns: Column) => ({
          field: columns.name,
          headerName: columns.localizedName
            ? columns.localizedName
            : columns.name,
          disableColumnMenu: true,
          show: columns.hidden === false,
          description: "",
          sorteable: columns.sortable,
          width: 200,
          operators: columns.filterOperators ? columns.filterOperators : [],
          static: columns.isStatic,
          alwaysHide: columns.alwaysHide ? columns.alwaysHide : false,
          isAnnotation: columns.isAnnotation ? columns.isAnnotation : false,
        }));

        const joinDetailColumnSwitches = detailsColumn.concat(idColumn);
        const detailsColumnsTransform = joinDetailColumnSwitches.map((x) => ({
          name: x.field,
          id: x.field,
          isSelected: x.show,
        }));

        const newArray = data.columns.map((columns: Column) => ({
          name: columns.localizedName ? columns.localizedName : columns.name,
          id: columns.name,
          isSelected: columns.hidden === false,
        }));

        const joinSwitchesColumns = detailsColumnsTransform.concat(newArray);

        const columnsVisibilityModelTranform = joinSwitchesColumns.map(
          (col: any) => {
            const visibility = {
              col: col.id,
              show: col.isSelected,
            };
            return visibility;
          }
        );

        const columnsVisibilityModel = columnsVisibilityModelTranform.reduce(
          (obj, item) => Object.assign(obj, { [item.col]: item.show }),
          {}
        );

        if (!comesFromPageNot0) {
          store.dispatch(setColumnVisibilityModel(columnsVisibilityModel));
        }
        const joinDetailColumn = detailsColumn.concat(columns);
        const join = idColumn.concat(joinDetailColumn);

        const filter = join.reduce((obj: any, col, idx) => {
          if (obj[col.field] === undefined) {
            obj[col.field] = idx;
          }
          return obj;
        }, {});

        const filteredColumns = join.filter(
          (col, idx) => filter[col.field] === idx
        );

        const newColumnPosition = filteredColumns
          ?.map((e) => e.field)
          .indexOf("username");

        if (!itRendersFromViews) {
          changePosInArray(filteredColumns, newColumnPosition, 0);
          store.dispatch(setColumns(filteredColumns));
        }
      }
      store.dispatch(isRenderingActionFromViews(false));
      store.dispatch(setComesfromPageNot0(true));

      return data;
    } catch (error: any) {
      return store.rejectWithValue(
        error.request.response ? error.request.response : error
      );
    }
  }
);

export const newDataExport = createAsyncThunk(
  "DataReducer/newDataExport",
  async (arg: { token: string }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const {
      dataSBFilters,
      dataExportModalValues,
      dataDateActivity,
      columnsToExport,
    } = (store.getState() as RootState).DataReducer;

    const { inputSearch } = dataSBFilters;
    const { email, typeOfExport } = dataExportModalValues;

    const headers = {
      Authorization: `Bearer ${arg.token}`,
      "Accept-Language": `${locale}`,
    };

    const { filtersCrops, filtersLocation, filterActions, filterUsers } =
      dataSBFilters;

    const columnsFilter = columnsToExport.filter(
      (n: ColumnSwitchesTypes) => n.isSelected
    );

    const columnsToExportAlpha = columnsFilter.map((col: any) => {
      if (col.id === "details")
        return {
          name: col.id,
          hidden: true,
          localizedName: col.name,
          isAnnotation: col.isAnnotation,
        };

      return {
        name: col.id,
        hidden: col.isSelected !== true,
        localizedName: col.name,
        isAnnotation: col.isAnnotation,
      };
    });

    const filterDate = {
      column: "pastActionCreationDate",
      operator: ">",
      value: setZeroHrs(dataDateActivity[0]).toISOString().slice(0, -1),
    };

    const filterDate2 = {
      column: "pastActionCreationDate",
      operator: "<",
      value: addHours(setZeroHrs(dataDateActivity[1]))
        .toISOString()
        .slice(0, -1),
    };

    const filters = [
      filtersCrops,
      filtersLocation,
      filterActions,
      filterUsers,
      filterDate,
      filterDate2,
    ];

    const filters2 = filters.filter(function (element) {
      return element !== undefined;
    });

    const dataWithFilters = {
      email,
      columns: columnsToExportAlpha,
      search: inputSearch,
      filtersOp: "and",
      filters: filters2,
    };

    const allData = {
      email,
      columns: columnsToExportAlpha,
      search: "",
      filtersOp: "and",
      filters: [],
    };

    const body =
      typeOfExport === "data_with_filters" ? dataWithFilters : allData;

    try {
      const response = await axios.post(
        `${API}/samples/new-data-export`,
        body,
        {
          headers,
        }
      );
      const { data } = response;
      store.dispatch(
        setExportModalValues({
          ...dataExportModalValues,
          typeOfExportSelected: "email",
        })
      );
      return store.fulfillWithValue(data);
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const createNewView = createAsyncThunk(
  "DataReducer/createNewView",
  async (arg: { token: string; userId: string }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { views, viewValues } = (store.getState() as RootState).DataReducer;

    store.dispatch(setViewsValues({ ...views, loadingViews: true }));
    try {
      const response = await axios({
        method: "POST",
        url: `${API}/tools/saved-config`,
        headers: {
          Authorization: `Bearer ${arg.token}`,
          "Accept-Language": `${locale}`,
        },
        data: {
          name: viewValues.name,
          description: viewValues.description,
          typeConstantId: parseInt(process.env.REACT_APP_CONSTANT_ID as string),
          config: viewValues,
        },
      });

      const { data } = response;

      store.dispatch(
        setViewsValues({ ...views, loadingViews: false, viewCreated: true })
      );

      return data;
    } catch (error) {
      store.dispatch(
        setViewsValues({
          ...views,
          loadingViews: false,
          errorViews: error,
          viewCreated: false,
        })
      );
    }
  }
);

export const getUserViews = createAsyncThunk(
  "DataReducer/getUserViews",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { views } = (store.getState() as RootState).DataReducer;
    store.dispatch(setViewsValues({ ...views, loadingViews: true }));
    try {
      const response = await axios.get(
        `${API}/tools/saved-config?&typeConstantId=${process.env.REACT_APP_CONSTANT_ID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": `${locale}`,
          },
        }
      );

      const { data } = response;
      store.dispatch(
        setViewsValues({ ...views, loadingViews: false, viewsResults: data })
      );

      return data;
    } catch (error) {
      store.dispatch(
        setViewsValues({ ...views, loadingViews: false, errorViews: error })
      );
    }
  }
);

export const fetchSamplesActions = createAsyncThunk(
  "DataReducer/fetchSamplesActions",
  async (arg: { token: string; page: number; rowOption: number }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { dataSBFilters, dataDateActivity } = (store.getState() as RootState)
      .DataReducer;

    const { filtersCrops, filtersLocation, filterActions, filterUsers } =
      dataSBFilters;

    const filterDate = {
      column: "pastActionCreationDate",
      operator: ">",
      value: setZeroHrs(dataDateActivity[0]).toISOString().slice(0, -1),
    };

    const filterDate2 = {
      column: "pastActionCreationDate",
      operator: "<",
      value: addHours(setZeroHrs(dataDateActivity[1]))
        .toISOString()
        .slice(0, -1),
    };

    const filters = [
      filtersCrops,
      filtersLocation,
      filterActions,
      filterUsers,
      filterDate,
      filterDate2,
    ];

    const filters2 = filters.filter(function (element) {
      return element !== undefined;
    });

    const manageSkip = () => {
      if (arg.page === 0) {
        return;
      } else {
        return `$skip=${arg?.page * arg.rowOption}`;
      }
    };

    const params = [
      manageSkip(),
      `$limit=${arg.rowOption}`,
      "$sort=[createdAt]=-0",
    ];
    const add = `&`;
    const body = {
      search: dataSBFilters.inputSearch,
      withLocalization: true,
      filtersOp: "and",
      filters: filters2,
    };
    const headers = {
      Authorization: `Bearer ${arg.token}`,
      "Accept-Language": `${locale}`,
    };
    try {
      const response = await axios.post(
        `${API}/samples/new-data-overview?${params.join(add)}`,
        body,
        {
          headers,
        }
      );
      const { data } = response;
      return data;
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const fetchDataArrays = createAsyncThunk(
  "DataReducer/fetchDataArrays",
  async (arg: { arrayPages: number[] }, store) => {
    const { user } = (store.getState() as RootState).Authentication;
    const { tableOptions } = (store.getState() as RootState).DataReducer;
    const token = user?.token as string;
    try {
      const fetchThemAll = arg.arrayPages.map((timesToFetch) =>
        store.dispatch(
          fetchSamplesActions({
            token: token,
            page: timesToFetch,
            rowOption: tableOptions.rowOptions,
          })
        )
      );
      const fetch = await Promise.all(fetchThemAll);
      const transform = fetch.map((data) => data.payload.data);
      const flatTransform = transform.flat();
      const mapPastActionId = flatTransform.map((info) => info.pastActionId);
      store.dispatch(setPastActionIds(mapPastActionId));
    } catch (error: any) {
      return store.rejectWithValue(error);
    }
  }
);
