import React, { FC, useEffect } from "react";
import { Alert, Box, Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  CreateLocationStep,
  CreateUsersLocation,
  initialState,
  setCreateLocationStep,
  setCreateUsersLoc,
  setRowsAlpha,
  setUserConfigRows,
} from "../../../../redux/slices/location/createLocation";
import { State } from "../../../../redux/slices";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setCreateUsersValues: (value: CreateUsersLocation) => {
      dispatch(setCreateUsersLoc(value));
    },

    setUserConfigRows: (value: any) => {
      dispatch(setUserConfigRows(value));
    },

    resetRowsAlpha: () => {
      dispatch(setRowsAlpha(initialState.rowsAlpha));
    },

    setSteps: (value: CreateLocationStep) => {
      dispatch(setCreateLocationStep(value));
    },
  };
};

export const StepTwoCreateUsers: FC<any> = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { setCreateUsersValues, setUserConfigRows, resetRowsAlpha, setSteps } =
    SetActions();
  const { createUsers, createLocationStep } = useSelector(
    (state: State) => state.CreateLocationReducer
  );
  const { numberOfUserToCreate, usersToCreate } = createUsers;

  const rowsTransform = usersToCreate.map((r, i) => ({
    id: i,
    username: `${r}-${i + 1}`,
    assignedSite: [],
    assignedAction: [],
    nomadUser: false,
  }));

  const repeatelem = function (elem: any, n: number) {
    let arr: any[] = [];

    for (let i = 0; i <= n; i++) {
      arr = arr.concat(elem);
    }

    return setCreateUsersValues({
      ...createUsers,
      usersToCreate: arr,
      numberOfUserToCreate: arr.length,
    });
  };

  const previewUsers = usersToCreate.map((user, i) => {
    if (user === undefined || null) {
      return `-${i + 1}`;
    } else {
      return `${user}-${i + 1}`;
    }
  });

  const nextStep = () => {
    setUserConfigRows(rowsTransform);
    navigate("/create-locations/configs");
  };

  useEffect(() => {
    resetRowsAlpha();
    setSteps({ ...createLocationStep, place: "" });
  }, []);

  return (
    <div className="steps-container-create-loc">
      <div className="step-title">{t("Create_site.create_users.step")}</div>
      <div>
        <div className="step-title-create">
          {t("Create_site.create_users.create")}
        </div>
        <div className="create-site-form" style={{ background: "#fff" }}>
          <Box
            component="form"
            sx={{ "& > :not(style)": { m: 1 } }}
            noValidate
            autoComplete="off"
          >
            <div className="input-create-loc-users-form">
              <TextField
                inputProps={{ style: { height: "31.5px" } }}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={t("Create_site.create_users.number_users")}
                type="number"
                variant="outlined"
                value={numberOfUserToCreate}
                onChange={(e) => {
                  repeatelem(
                    usersToCreate[0],
                    parseInt(e.currentTarget.value) - 1
                  );
                }}
              />
            </div>
            <div className="create-site-users-container">
              <div className="create-info-loc-user-names">
                <TextField
                  inputProps={{ style: { height: "31.5px" } }}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={t("Create_site.create_users.usernames")}
                  placeholder={t("Create_site.create_users.placeholder")}
                  variant="outlined"
                  value={usersToCreate[0]}
                  onChange={(e) => {
                    repeatelem(e.currentTarget.value, numberOfUserToCreate - 1);
                  }}
                />
              </div>
              <Alert
                sx={{
                  width: "580px",
                  maxWidth: "calc(100% - 20px)",
                }}
                severity="info"
              >
                {usersToCreate.length >= 1
                  ? `${t(
                      "Create_site.create_users.previewOneUsers"
                    )}  ${previewUsers}`
                  : t("Create_site.create_users.previewone")}
              </Alert>
            </div>
          </Box>
        </div>
        <Button
          disabled={numberOfUserToCreate === 0}
          variant="contained"
          sx={{ textTransform: "none", marginTop: "24px" }}
          onClick={nextStep}
        >
          {t("Create_site.stepone.Continue")}
        </Button>
      </div>
      <div className="background-components-fix" />
    </div>
  );
};

export default StepTwoCreateUsers;
