import React, { FC, useEffect } from "react";

import { Box, Button, SelectChangeEvent } from "@mui/material";
import {
  DataGridPro,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridToolbarContainer,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { ThemeProvider } from "@mui/material/styles";

import themeInarix from "../../../StylesMuiInarix";
import CreateLocationsColumnsRows from "./FetchColumnsConfig";
import { useTranslation } from "react-i18next";
import { State } from "../../../../redux/slices";
import { useDispatch, useSelector } from "react-redux";
import { MenuCreateLocation } from "../../CreateLocation";
import { useNavigate } from "react-router-dom";
import {
  CreateLocationStep,
  resetStateCreatLocUsers,
  setColumns,
  setCreateLocationStep,
  setRowsAlpha,
} from "../../../../redux/slices/location/createLocation";
import AddIcon from "@mui/icons-material/Add";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { createLocation } from "../../../../redux/actions/locations/locationsAsyncActions";
import { TokenPayload } from "../../../../redux/actions/authentication/authentication";
import jwt from "jsonwebtoken";
import Alert from "@mui/material/Alert";

const SetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  return {
    setRowsAlpha2: (value: any) => {
      dispatch(setRowsAlpha(value));
    },
    setSteps: (value: CreateLocationStep) => {
      dispatch(setCreateLocationStep(value));
    },
    cancel: () => {
      dispatch(resetStateCreatLocUsers());
    },
    setColumnIndex: (columns: any) => {
      dispatch(setColumns(columns));
    },

    setStep: (value: CreateLocationStep) => {
      dispatch(setCreateLocationStep(value));
    },
    createLocations: (token: string, realmId: number) => {
      dispatch(createLocation({ token, realmId }));
    },
  };
};

export const StepConfigCreateUsers: FC<any> = () => {
  const apiRef = useGridApiRef();
  const [t] = useTranslation("global");
  const { user } = useSelector((state: State) => state.Authentication);
  const navigate = useNavigate();

  const { setSteps, cancel, setColumnIndex, setRowsAlpha2, createLocations } =
    SetActions();
  const {
    createLocationStep,
    usersConfigRows,
    columns,
    rowsAlpha,
    errorCreateLocations,
    errorCreateUsers,
  } = useSelector((state: State) => state.CreateLocationReducer);

  const { locale } = useSelector((state: State) => state.LayoutReducer);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const token = user?.token ?? "";
  const { realmId }: TokenPayload = jwt.decode(token) as TokenPayload;

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRowsAlpha2(rowsAlpha.filter((row: any) => row.id !== id));
  };
  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rowsAlpha.find((row: any) => row.id === id);
    if (editedRow!.isNew) {
      setRowsAlpha2(rowsAlpha.filter((row: any) => row.id !== id));
    }
  };

  const toggleNomad = (id: GridRowId) => () => {
    setRowsAlpha2(
      rowsAlpha.map((row: any) =>
        row.id === id
          ? {
              ...row,
              nomadUser: !row.nomadUser,
              assignedSite: !row.nomadUser
                ? ["Nomad user doesn’t have assigned site"]
                : [undefined],
            }
          : row
      )
    );
  };

  const handleChange = (event: SelectChangeEvent<any>, id: string) => {
    const {
      target: { value },
    } = event;

    setRowsAlpha2(
      rowsAlpha.map((row: any) =>
        row.id === id
          ? {
              ...row,
              assignedSite: value === "string" ? value.split(",") : value,
            }
          : row
      )
    );
  };

  const handleChangeActions = (event: SelectChangeEvent<any>, id: string) => {
    const {
      target: { value },
    } = event;

    setRowsAlpha2(
      rowsAlpha.map((row: any) =>
        row.id === id
          ? {
              ...row,
              assignedAction: value === "string" ? value.split(",") : value,
            }
          : row
      )
    );
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRowsAlpha2(
      rowsAlpha.map((row: any) => (row.id === newRow.id ? updatedRow : row))
    );
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const { rawColumns } = CreateLocationsColumnsRows(
    columns,
    rowModesModel,
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleDeleteClick,
    toggleNomad,
    handleChange,
    handleChangeActions
  );

  useEffect(() => {
    if (rowsAlpha.length === 0) {
      setRowsAlpha2(usersConfigRows);
    }

    setSteps({ ...createLocationStep, place: "configs" });

    window.onbeforeunload = function () {
      window.setTimeout(function () {
        window.location = "/create-locations/" as any;
      }, 0);
      window.onbeforeunload = null; // necessary to prevent infinite loop
    };
  }, []);

  const editToolbar = () => {
    const handleClick = () => {
      const id = Math.random();

      setRowsAlpha2([
        ...rowsAlpha,
        {
          id,
          username: "",
          assignedSite: [],
          assignedAction: [],
          nomadUser: false,
          isNew: true,
        },
      ]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "username" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          {t("Create_site.config_users.add_user")}
        </Button>
      </GridToolbarContainer>
    );
  };

  const managingError = () => {
    if (errorCreateLocations) {
      return (
        <Alert severity="error">{JSON.stringify(errorCreateLocations)}</Alert>
      );
    }
    if (errorCreateUsers) {
      return <Alert severity="error">{JSON.stringify(errorCreateUsers)}</Alert>;
    }

    if (!errorCreateLocations && !errorCreateUsers) {
      return (
        <Alert severity="info">{t("Create_site.config_users.user_pass")}</Alert>
      );
    }
  };

  return (
    <div className="create-sites-container">
      {MenuCreateLocation(createLocationStep, t, setSteps, navigate, cancel)}
      <div className="steps-container-create-loc">
        <div className="step-title">{t("Create_site.config_users.step")}</div>
        <div>
          <div className="step-title-create">
            {t("Create_site.config_users.config")}
          </div>
          <div className="design-scope">
            {t("Create_site.config_users.config_scope")}
          </div>
          {managingError()}
          <div className="create-site-form" style={{ background: "#fff" }}>
            <Box sx={{ height: 420, width: 1 }}>
              <ThemeProvider theme={themeInarix(locale)}>
                <DataGridPro
                  rows={
                    rowsAlpha.length === 0
                      ? [
                          {
                            id: "1",
                            username: "",
                            assignedSite: "",
                            assignedAction: "",
                            nomadUser: false,
                          },
                        ]
                      : rowsAlpha
                  }
                  columns={
                    rawColumns.length === 0
                      ? [
                          {
                            field: "id",
                            sortable: true,
                            width: 100,
                          },
                        ]
                      : rawColumns
                  }
                  onColumnOrderChange={() => {
                    setColumnIndex(apiRef.current.getAllColumns());
                  }}
                  onColumnWidthChange={() =>
                    setColumnIndex(apiRef.current.getAllColumns())
                  }
                  editMode="row"
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  pageSizeOptions={[25, 50, 100]}
                  slots={{
                    footer: editToolbar as any["footer"],
                  }}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 25 } },
                  }}
                  apiRef={apiRef}
                  rowHeight={70}
                />
              </ThemeProvider>
            </Box>
            <Button
              onClick={() => {
                createLocations(token, realmId);
              }}
              sx={{ textTransform: "none", width: "79px", marginTop: "24px" }}
              variant="contained"
            >
              {t("Create_site.config_users.create")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepConfigCreateUsers;
