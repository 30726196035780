import React from "react";
import { Skeleton } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const handlingSizeOptionsModal = (options: any[]) => {
  if (options.length > 6) {
    return "option-chips-scroll";
  }
  if (options.length) {
    return "";
  }
};

export function withRouter<ComponentProps>(
  Component: React.FunctionComponent<ComponentProps>
) {
  function ComponentWithRouterProp(props: Readonly<ComponentProps>) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export const skeletonGroupsActionsAccordions = () => (
  <div className="accordion-actions-styles">
    <Skeleton className="mb-2" variant="rectangular" width={320} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={320} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={320} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={320} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={320} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={320} height={70} />
  </div>
);

export const skeletonSearchLocatioMap = () => (
  <div className="accordion-actions-styles">
    <Skeleton className="mb-2" variant="rectangular" width={350} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={350} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={350} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={350} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={350} height={70} />
    <Skeleton className="mb-2" variant="rectangular" width={350} height={70} />
  </div>
);

export const removeUndefined = (arrayWithUndefined: any[]) => {
  const remove = arrayWithUndefined.filter(function (element) {
    return element !== undefined;
  });

  return remove;
};
