import { createSlice } from "@reduxjs/toolkit";
import { DColumns } from "../../actions/data/DataDeclarations";
import {
  createLocation,
  userCreation,
} from "../../actions/locations/locationsAsyncActions";

export interface CreateLocationStep {
  step: number;
  option: string;
  place: string;
  locationValidate: boolean;
}

export interface CreateNewSite {
  siteName: string;
  group: string[];
  siteAddress: string;
  longitude: number;
  latitude: number;
  identifier: string;
  countryName: string;
  createNewUsers: boolean;
}

export interface CreateUsersLocation {
  numberOfUserToCreate: number;
  usersNames: string;
  usersToCreate: string[];
}

export interface CreateLocationState {
  createLocationStep: CreateLocationStep;
  createNewSite: CreateNewSite;
  createUsers: CreateUsersLocation;
  searchValues: {
    sites: string;
    actions: string;
  };
  error: string;
  errorCreateUsers: string;
  errorCreateLocations: string;
  usersConfigRows: any[];
  rowsAlpha: any[];
  columns: any[];
  actionsValues: any[];
  loadingCreateLocation: boolean;
  createLocationSuccess: boolean;
  createUsersSuccess: boolean;
}

export const initialState: CreateLocationState = {
  createLocationStep: {
    step: 0,
    option: "",
    place: "",
    locationValidate: true,
  },
  createNewSite: {
    siteName: "",
    group: [],
    siteAddress: "",
    longitude: 0,
    latitude: 0,
    identifier: "",
    countryName: "",
    createNewUsers: true,
  },

  createUsers: {
    numberOfUserToCreate: 0,
    usersNames: "",
    usersToCreate: [],
  },

  searchValues: {
    sites: "",
    actions: "",
  },

  usersConfigRows: [],
  rowsAlpha: [],
  columns: [
    {
      field: "id",
      sortable: true,
      width: 100,
      alwaysHide: true,
    },

    {
      field: "username",
      sortable: true,
      width: 200,
      alwaysHide: false,
    },
    {
      field: "assignedSite",
      sortable: false,
      width: 240,
      alwaysHide: false,
    },
    {
      field: "assignedAction",
      sortable: false,
      width: 240,
      alwaysHide: false,
    },

    {
      field: "nomadUser",
      sortable: false,
      width: 200,
      alwaysHide: false,
    },
    {
      field: "actions",
      sortable: false,
      width: 200,
      alwaysHide: false,
    },
  ] as DColumns[],
  error: "",
  errorCreateLocations: "",
  errorCreateUsers: "",
  actionsValues: [{ name: "", id: 0 }],
  loadingCreateLocation: false,
  createLocationSuccess: false,
  createUsersSuccess: false,
};

export const CreateLocationSlice = createSlice({
  name: "CreateLocationReducer",
  initialState,
  reducers: {
    setActionValues(state, action) {
      return {
        ...state,
        actionsValues: action.payload,
      };
    },
    setSearchValues(state, action) {
      return {
        ...state,
        searchValues: action.payload,
      };
    },
    setRowsAlpha(state, action) {
      return {
        ...state,
        rowsAlpha: action.payload,
      };
    },
    setColumns(state, action) {
      return {
        ...state,
        columns: action.payload,
      };
    },
    setUserConfigRows(state, action) {
      return {
        ...state,
        usersConfigRows: action.payload,
      };
    },
    setCreateUsersLoc(state, action) {
      return {
        ...state,
        createUsers: action.payload,
      };
    },
    setErrorCreateLoc(state, action) {
      return {
        ...state,
        error: action.payload,
      };
    },
    setCreateLocationStep(state, action) {
      return {
        ...state,
        createLocationStep: action.payload,
      };
    },

    CreateNewSiteSetValues(state, action) {
      return {
        ...state,
        createNewSite: action.payload,
      };
    },

    resetStateCreatLocUsers(state) {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(userCreation.pending, (state) => {
        state.loadingCreateLocation = true;
        state.createUsersSuccess = false;
        state.errorCreateUsers = "";
      })
      .addCase(userCreation.fulfilled, (state) => {
        state.loadingCreateLocation = false;
        state.createUsersSuccess = true;
      })
      .addCase(userCreation.rejected, (state, action) => {
        state.loadingCreateLocation = false;
        state.createUsersSuccess = false;
        state.errorCreateUsers = action.payload as string;
      });

    builder
      .addCase(createLocation.pending, (state) => {
        state.loadingCreateLocation = true;
        state.createLocationSuccess = false;
        state.errorCreateLocations = "";
      })
      .addCase(createLocation.fulfilled, (state) => {
        state.loadingCreateLocation = false;
        state.createLocationSuccess = true;
      })
      .addCase(createLocation.rejected, (state, action) => {
        state.loadingCreateLocation = false;
        state.createLocationSuccess = false;
        state.errorCreateLocations = action.payload as string;
      });
  },
});

export const {
  setActionValues,
  setSearchValues,
  setRowsAlpha,
  setColumns,
  setUserConfigRows,
  setCreateUsersLoc,
  setCreateLocationStep,
  CreateNewSiteSetValues,
  setErrorCreateLoc,
  resetStateCreatLocUsers,
} = CreateLocationSlice.actions;

export const CreateLocation = CreateLocationSlice.reducer;
