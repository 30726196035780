import axios from "axios";
import { API } from "../../../config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../configureStore";
import {
  fetchLocationsWithActivity,
  managingUndefined,
} from "../map/mapAsyncActions";
import { setModalLocations } from "../../slices/fleet/locations";
import {
  CreateNewSiteSetValues,
  setActionValues,
  setCreateLocationStep,
  setErrorCreateLoc,
} from "../../slices/location/createLocation";
import { ScenarioData } from "../actions/actionTypes";

export const patchLocationName = createAsyncThunk(
  "LocationsReducer/patchLocationName",
  async (token: string, store) => {
    const { locationId, editLocation } = (store.getState() as RootState)
      .LocationsReducer;
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { name, type, primaryContactName, telephone, email, address, notes } =
      editLocation;

    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios.patch(
        `${API}/users/organization-geo-point/${locationId}`,
        {
          name: managingUndefined(name),
          typeId: managingUndefined(parseInt(type)),
          primaryContactName: managingUndefined(primaryContactName),
          telephone: managingUndefined(telephone),
          email: managingUndefined(email),
          address: managingUndefined(address),
          notes: managingUndefined(notes),
          altitude: 0,
        },
        { headers }
      );

      store.dispatch(setModalLocations(false));
      store.dispatch(fetchLocationsWithActivity(token as unknown as string));
      return store.fulfillWithValue("success");
    } catch (error) {
      return store.rejectWithValue(error);
    }
  }
);

export const fetchLocationMapboxCoordinates = createAsyncThunk(
  "CreateLocationReducer/fetchLocationMapbox",
  async (arg: { longitude: number; latitude: number }, store) => {
    const { createNewSite, createLocationStep } = (
      store.getState() as RootState
    ).CreateLocationReducer;

    const { locale } = (store.getState() as RootState).LayoutReducer;

    try {
      const response = await axios.get(
        `https://api.mapbox.com/search/geocode/v6/reverse?longitude=${arg.longitude}&latitude=${arg.latitude}&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
        {
          headers: {
            "Accept-Language": `${locale}`,
          },
        }
      );
      const { data } = response;
      const values = data.features[0];
      if (values) {
        store.dispatch(
          CreateNewSiteSetValues({
            ...createNewSite,
            siteAddress: values.properties.full_address,
            countryName: values.properties.context.country.name,
            longitude: values.properties.coordinates.longitude,
            latitude: values.properties.coordinates.latitude,
          })
        );
        store.dispatch(setErrorCreateLoc(""));
      } else {
        store.dispatch(
          setCreateLocationStep({
            ...createLocationStep,
            step: 1,
            locationValidate: false,
          })
        );
      }
    } catch (error: any) {
      store.dispatch(setErrorCreateLoc(error.response.data.message));
      store.dispatch(
        setCreateLocationStep({
          ...createLocationStep,
          step: 1,
          locationValidate: false,
        })
      );
      store.rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchActionsNamesUsersConfig = createAsyncThunk(
  "CreateLocationReducer/fetchActionsNamesUsersConfig",
  async (token: string, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;

    try {
      const response = await axios.get(
        `${API}/core/scenario?isSystemScenario=false&deletedAt=null&statusConstantId[$in][]=34&statusConstantId[$in][]=35&$eager=[scopes.[group], statusConstant, name.[localizedTexts.[locale]], desc.[localizedTexts.[locale]]]`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": `${locale}`,
          },
        }
      );

      const { data } = response;

      const actionsValues = data.data.map((row: ScenarioData) => {
        const scenarioNameValue = () => {
          if (row.name) {
            let def = "";
            const arr = row.name.localizedTexts
              .map((c: any) => {
                if (locale === c.locale.value) {
                  return c.translation;
                }
                def = c.translation;
              })
              .filter((val: any) => null != val);
            if (!arr.length) {
              return [def].toString();
            } else {
              return arr.toString();
            }
          } else {
            return "Null";
          }
        };

        const tableRows = {
          name: scenarioNameValue().toLowerCase(),
          id: row.id,
        };
        return tableRows;
      });

      store.dispatch(setActionValues(actionsValues));
      return data.data;
    } catch (error) {
      return error;
    }
  }
);

export const userCreation = createAsyncThunk(
  "CreateLocationReducer/userCreation",
  async (
    arg: {
      token: string;
      username: string;
      password: string;
      associatedGeoPointId: number;
    },
    store
  ) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;

    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${arg.token}`,
    };

    try {
      const response = await axios.post(
        `${API}/users/register`,
        {
          username: arg.username,
          password: arg.password,
          userType: "site",
          email: "",
          remoteId: "",
          firstname: "",
          lastname: "",
          associatedGeoPointId: arg.associatedGeoPointId,
        },
        { headers }
      );

      const { data } = response;
      return data;
    } catch (error: any) {
      return store.rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const createLocation = createAsyncThunk(
  "CreateLocationReducer/createLocation",
  async (arg: { token: string; realmId: number }, store) => {
    const { locale } = (store.getState() as RootState).LayoutReducer;
    const { createNewSite, rowsAlpha } = (store.getState() as RootState)
      .CreateLocationReducer;

    const { siteName, siteAddress, longitude, latitude, countryName } =
      createNewSite;

    const headers = {
      "Accept-Language": `${locale}`,
      Authorization: `Bearer ${arg.token}`,
    };

    try {
      const response = await axios.post(
        `${API}/users/organization-geo-point`,
        {
          name: siteName,
          orgId: arg.realmId,
          typeId: 1,
          latitude,
          longitude,
          address: siteAddress,
          altitude: 0,
          countryName,
        },
        { headers }
      );

      const { data } = response;

      const usersPromises = rowsAlpha.map((usersConfigs) =>
        store.dispatch(
          userCreation({
            token: arg.token,
            username: usersConfigs.username,
            password: "password",
            associatedGeoPointId: data.id,
          })
        )
      );
      const move = await Promise.all(usersPromises);
      return move;
    } catch (error: any) {
      return store.rejectWithValue(error?.response?.data?.message);
    }
  }
);
